<template>
  <v-container class="py-6 container--fluid" >
    <v-row>
      <v-col cols="12">
        <div class="box-wrapper">
          <div
            class="box-overlay"
            :class="{'open': isSidebar}"
            @click="isSidebar = !isSidebar"
          />
          <div
            class="box-sidebar pb-3 shadow-sm"
            :class="{'open': isSidebar}"
          >
            <profile-menu />
          </div>
          <div class="box-content">
            <div class="box-container">
              <div class="d-flex justify-end pa-2 d-block d-lg-none">
                <v-btn
                  icon
                  @click="isSidebar = !isSidebar"
                >
                  <v-icon dark>
                    mdi-format-list-bulleted-square
                  </v-icon>
                </v-btn>
              </div>
              <router-view />
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  import ProfileMenu from '../../components/ProfileMenu.vue'
  export default {
    components: {
      ProfileMenu,
    },
    data () {
      return {
        isSidebar: false,
        items: ['Foo', 'Bar', 'Fizz', 'Buzz'],
      }
    },

  }
</script>
